// making this static for seo, todo fix
export const availableStoriesStaticConfig = [
    {
        id: 'medieval',
        name: 'Medieval adventure',
        description: 'An adventure to the Middle Ages of the Eastern Europe.',
        imageUrl:
            'https://questaia4fd.blob.core.windows.net/story-previews/medieval.jpg',
    },
    {
        id: 'magical',
        name: 'Magical adventure',
        description: 'Dive into the world of magic.',
        imageUrl:
            'https://questaia4fd.blob.core.windows.net/story-previews/magical.jpg',
    },
    {
        id: 'sci-fi',
        name: 'Sci-fi adventure',
        description: 'It is time to the world, and maybe other worlds...',
        imageUrl:
            'https://questaia4fd.blob.core.windows.net/story-previews/sci-fi.jpg',
    },
    {
        id: 'love-story',
        name: 'Love Story',
        description:
            'Navigate the twists and turns of romance in an interactive love story game where your choices shape your destiny and relationships.',
        imageUrl:
            'https://questaia4fd.blob.core.windows.net/story-previews/love-story.jpg',
    },
    {
        id: 'zombies',
        name: 'Zombie Apocalypse',
        description:
            'Fight for survival in a gripping zombie apocalypse game where every decision impacts your fate and the safety of your companions.',
        imageUrl:
            'https://questaia4fd.blob.core.windows.net/story-previews/zombies.jpg',
    },
];
