import { availableStoriesStaticConfig } from '../services/static-config';

function StoryLink({ storyId }) {
    return (
        <li>
            <a
                href={`/story/${storyId}`}
                className="hover:text-amber-300 transition-colors"
            >
                {
                    availableStoriesStaticConfig.find((x) => x.id === storyId)
                        .name
                }
            </a>
        </li>
    );
}

export default function StoriesFooter() {
    return (
        <footer className="bg-gray-800 text-amber-100 py-8 font-serif">
            <div className="max-w-6xl mx-auto px-4">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    <div>
                        <h3 className="text-xl font-bold text-amber-300 mb-4">
                            Story Categories
                        </h3>
                        <ul className="space-y-2">
                            <StoryLink storyId="medieval"></StoryLink>
                            <StoryLink storyId="magical"></StoryLink>
                            <StoryLink storyId="sci-fi"></StoryLink>
                        </ul>
                    </div>
                    <div>
                        <h3 className="text-xl font-bold text-amber-300 mb-4">
                            Popular Stories
                        </h3>
                        <ul className="space-y-2">
                            <StoryLink storyId="zombies"></StoryLink>
                            <StoryLink storyId="love-story"></StoryLink>
                        </ul>
                    </div>
                    <div>
                        <h3 className="text-xl font-bold text-amber-300 mb-4">
                            Storiented
                        </h3>
                        <ul className="space-y-2">
                            <li>
                                <a
                                    href="/about"
                                    className="hover:text-amber-300 transition-colors"
                                >
                                    About Us
                                </a>
                            </li>
                            <li>
                                <a
                                    href="/how-it-works"
                                    className="hover:text-amber-300 transition-colors"
                                >
                                    How It Works
                                </a>
                            </li>
                            <li>
                                <a
                                    href="/privacy-policy.html"
                                    className="hover:text-amber-300 transition-colors"
                                >
                                    Privacy Policy
                                </a>
                            </li>
                            <li>
                                <a
                                    href="/terms"
                                    className="hover:text-amber-300 transition-colors"
                                >
                                    Terms of Service
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="mt-8 pt-8 border-t border-gray-700 text-center">
                    <p>&copy; 2024 Storiented. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
}
