import { useEffect, useState, useRef } from 'react';
import { ApiService } from '../services/api-service';
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from '@headlessui/react';
import ClipLoader from 'react-spinners/ClipLoader';
import * as localization from '../services/localization';

const apiService = new ApiService();

export default function GameHistory({
    gameId,
    userId,
    cursor,
    open,
    setOpen,
    language,
}) {
    const [historyRows, setHistoryRows] = useState([]);
    const [currentCursor, setCurrentCursor] = useState(cursor);
    const [loadedCursor, setLoadedCursor] = useState(null);
    const initialized = useRef(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (initialized.current) {
            return;
        }

        initialized.current = true;

        setLoading(true);
        fetchHistory();
    }, []);

    function hasMore() {
        return currentCursor && currentCursor !== loadedCursor;
    }

    async function fetchHistory() {
        if (!hasMore()) {
            return;
        }

        const { history } = await apiService.getGameHistory({
            userId,
            gameId,
            cursor: currentCursor,
        });

        const newCursor = history?.length
            ? history[history.length - 1].historyId
            : null;

        setHistoryRows([
            ...history.reverse().filter((x) => x.text),
            ...historyRows,
        ]);
        setLoadedCursor(currentCursor);
        setCurrentCursor(newCursor);
        setLoading(false);
    }

    return (
        <Dialog open={open} onClose={setOpen} className="relative z-10">
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen">
                <div className="flex items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className=" relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full mx-3 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <ClipLoader
                            color={'#36d7b7'}
                            loading={loading}
                            size={100}
                            cssOverride={{
                                zIndex: 100,
                                margin: 'auto',
                                left: 0,
                                right: 0,
                                top: 0,
                                bottom: 0,
                                position: 'absolute',
                            }}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                        <div className=" bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                            <div className="sm:flex sm:items-start">
                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left ">
                                    <div className="flex">
                                        {hasMore() && (
                                            <button onClick={fetchHistory}>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke-width="1.5"
                                                    stroke="currentColor"
                                                    class="size-6"
                                                >
                                                    <path
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18"
                                                    />
                                                </svg>
                                            </button>
                                        )}
                                        <div className="flex-1"></div>
                                        <button onClick={() => setOpen(false)}>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="currentColor"
                                                class="size-6"
                                            >
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    d="M6 18 18 6M6 6l12 12"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                    <ul
                                        role="list"
                                        className="h-[500px] divide-y overflow-y-auto divide-gray-100"
                                    >
                                        {historyRows.map((row) => (
                                            <li
                                                key={row.historyId}
                                                className={
                                                    loadedCursor ===
                                                    row.historyId
                                                        ? 'bg-gray-200'
                                                        : ''
                                                }
                                            >
                                                <div className="py-5 flex">
                                                    <div className="mr-5">
                                                        {row.role ===
                                                            'model' && (
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                strokeWidth="1.5"
                                                                stroke="currentColor"
                                                                className="size-6"
                                                            >
                                                                <path
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                    d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418"
                                                                />
                                                            </svg>
                                                        )}
                                                        {row.role ===
                                                            'user' && (
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                strokeWidth="1.5"
                                                                stroke="currentColor"
                                                                className="size-6"
                                                            >
                                                                <path
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                    d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                                                                />
                                                            </svg>
                                                        )}
                                                    </div>
                                                    <p>{row.text}</p>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                            <button
                                type="button"
                                onClick={() => setOpen(false)}
                                className="inline-flex w-full justify-center rounded-md bg-yellow-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 sm:ml-3 sm:w-auto"
                            >
                                {localization.close[language]}
                            </button>
                        </div> */}
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}
