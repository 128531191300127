import { useNavigate, useParams } from 'react-router-dom';
import { ArrowRight, Sparkles } from 'lucide-react';
import { availableStoriesStaticConfig } from './services/static-config';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

// making this static for seo, todo fix
const stories = availableStoriesStaticConfig;

/**
 * Return a random element from an array that is
 * different than `last` (as long as the array has > 1 items).
 * Return null if the array is empty.
 */
function getRandomDifferent(arr, last = undefined) {
    if (arr.length === 0) {
        return null;
    } else if (arr.length === 1) {
        return arr[0];
    } else {
        let num = 0;
        do {
            num = Math.floor(Math.random() * arr.length);
        } while (arr[num] === last);
        return arr[num];
    }
}

export default function StoryShowcase() {
    let { storyId } = useParams();
    const [story, setStory] = useState({});
    const [links, setLinks] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const s = stories.find((x) => x.id === storyId);
        if (s) {
            setStory(s);
        } else {
            storyId = 'medieval';
            setStory(stories.find((x) => x.id === storyId));
        }

        const links = stories.filter((x) => x.id !== storyId);
        const link1 = getRandomDifferent(links);
        const link2 = getRandomDifferent(links, link1);
        setLinks([link1, link2]);
    }, []);

    const storyTitle = story.name;
    const storyDescription = story.description;
    const imageUrl = story.imageUrl;

    return (
        <>
            <Helmet>
                <title>{storyTitle + ' | Storiented AI Quest Generator'}</title>
                <meta name="description" content={storyDescription} />
                <meta
                    property="og:title"
                    content={`${storyTitle} | Storiented AI Quest Generator`}
                />
                <meta property="og:description" content={storyDescription} />
                <meta property="og:image" content={imageUrl} />
                <meta property="og:type" content="article" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <div className="min-h-screen bg-gray-900 text-amber-100 font-serif">
                <main className="max-w-4xl mx-auto px-4 py-8">
                    <header className="mb-8">
                        <h1 className="text-4xl md:text-5xl font-bold text-amber-300 mb-4">
                            {storyTitle}
                        </h1>
                        <p className="text-xl text-amber-200">
                            {storyDescription}
                        </p>
                    </header>

                    <section className="mb-12">
                        <div className="relative w-full aspect-video mb-4">
                            <img
                                src={imageUrl}
                                alt={`Illustration of The {storyTitle} story`}
                                layout="fill"
                                objectFit="cover"
                                className="rounded-lg"
                                priority
                            />
                        </div>
                        <button
                            onClick={() => navigate(`/start/${storyId}`)}
                            className="cursor-pointer w-full flex items-center sm:w-auto px-6 py-3 text-lg bg-amber-500 hover:bg-amber-600 text-gray-900 shadow-md transition-all hover:shadow-lg font-medium rounded-full"
                        >
                            <span>Begin Your Quest </span>
                            <ArrowRight className="ml-2 h-5 w-5" />
                        </button>
                    </section>

                    <section className="bg-gray-800 rounded-lg p-6 mb-8">
                        <h2 className="text-2xl font-bold text-amber-300 mb-4 flex items-center">
                            <Sparkles className="mr-2 h-6 w-6" />
                            How AI-Generated Stories Work
                        </h2>
                        <p className="text-amber-100 mb-4">
                            Storiented uses advanced AI algorithms to create
                            unique, immersive quests tailored to your choices.
                            Our system:
                        </p>
                        <ul className="list-disc list-inside text-amber-200 space-y-2">
                            <li>
                                Analyzes vast databases of storytelling elements
                            </li>
                            <li>
                                Generates coherent narratives based on your
                                decisions
                            </li>
                            <li>
                                Adapts the story in real-time to your choices
                            </li>
                            <li>
                                Ensures each quest is unique and personalized
                            </li>
                        </ul>
                    </section>

                    <section>
                        <h2 className="text-2xl font-bold text-amber-300 mb-4">
                            Explore More Quests
                        </h2>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            {links.map((link) => (
                                <a
                                    key={link.id + 'other_quest'}
                                    href={`/story/${link.id}`}
                                    className="bg-gray-800 rounded-lg p-4 cursor-pointer"
                                >
                                    <h3 className="text-xl font-bold text-amber-200 mb-2">
                                        {link.name}
                                    </h3>
                                    <p className="text-amber-100">
                                        {link.description}
                                    </p>
                                </a>
                            ))}
                        </div>
                    </section>
                </main>

                <footer className="bg-gray-800 mt-12 py-6">
                    <div className="max-w-4xl mx-auto px-4 text-center">
                        <p className="text-amber-200">
                            &copy; 2024 Storiented. All rights reserved.
                        </p>
                    </div>
                </footer>
            </div>
        </>
    );
}
