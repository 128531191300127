export class ApiService {
    constructor() {
        this.baseUrl = process.env.REACT_APP_BASE_BE_URL;
    }

    async login({ userId, language, screenDimensions }) {
        let url = `${this.baseUrl}/api/login`;
        if (userId) {
            url += `/${userId}`;
        }

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                language,
                // screenDimensions,
                screen: {
                    w: window.innerWidth,
                    h: window.innerHeight,
                },
            }),
        });

        return response.json();
    }

    async getGame({ userId, gameId }) {
        const response = await fetch(
            `${this.baseUrl}/api/${userId}/game/${gameId}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

        return response.json();
    }

    async startGame({ userId, storyId }) {
        try {
            const version = localStorage.getItem('prompt-version');
            const response = await fetch(
                `${this.baseUrl}/api/${userId}/game/start/${storyId}${
                    version ? `?version=${version}` : ''
                }`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            return response.json();
        } catch (err) {
            console.error(err);
            window.location.reload();
            throw err;
        }
    }

    async executeAction({ userId, gameId, actionId }) {
        try {
            const response = await fetch(
                `${this.baseUrl}/api/${userId}/game/${gameId}/action/${actionId}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            return response.json();
        } catch (err) {
            console.error(err);
            window.location.reload();
            throw err;
        }
    }

    async deleteGames({ userId }) {
        await fetch(`${this.baseUrl}/api/${userId}/game/all`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    async getGameHistory({ userId, gameId, cursor }) {
        const response = await fetch(
            `${this.baseUrl}/api/${userId}/game/${gameId}/history/${cursor}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

        return response.json();
    }
}
